import { useNavigate } from 'react-router-dom'
import logo from '../icon/logo2.jpeg'
import fb from '../icon/fb.png'
import ig from '../icon/ig.png'
import linkedin from '../icon/in.png'
import { useEffect, useState } from 'react'
import { db } from '../Firebase'

const Header = () => {
  const navigate = useNavigate()
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const [openPortraits, setOpenPortraits] = useState(false)
  const [slides, setSlides] = useState([])
  const [open, setOpen] = useState(false)
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    db.collection("photos")
      .orderBy("order", "asc")
      .get()
      .then(snapshot => {
        const dbSlides = []
        snapshot.forEach(c => dbSlides.push(c.data()))
        setSlides(dbSlides)
      })
  }, [])

  return <div className="md:w-80 w-full md:p-4 flex flex-col items-center h-full">
    {windowSize[0] > 767 ?
      <>
        <img alt="logo" src={logo} className="md:w-64 w-20 p-4 cursor-pointer" onClick={() => navigate('/')} />
        <button className='text-l pt-4' onClick={() => navigate('/bio')}>Biografia</button>
        <hr className='m-2 bg-black w-64' />
        <button className={openPortfolio ? 'text-l font-bold mb-2 shadow-lg w-64' : 'text-l'} onClick={() => {
          setOpenPortfolio(!openPortfolio)
          setOpenPortraits(false)
        }
        }>Portfolio</button>
        <div className=' overflow-y-auto max-h-60 w-full flex flex-col'>
          {openPortfolio && slides.filter(p => p.myValue === 'Portfolio').map(photos => {
            return <button className='pb-2' key={photos.title} onClick={() => navigate(`${photos.myValue}/${photos.title}`)}>{photos.title}</button>
          })}
        </div>
        <hr className='m-2 bg-black w-64' />
        <button className={openPortraits ? 'text-l font-bold mb-2 shadow-lg w-64' : 'text-l'} onClick={() => {
          setOpenPortraits(!openPortraits)
          setOpenPortfolio(false)
        }
        }>Ritratti</button>
        <div className=' overflow-y-auto max-h-60 w-full flex flex-col'>
          {openPortraits && slides.filter(p => p.myValue === 'Ritratti').map(photos => {
            return <button className='pb-2' key={photos.title} onClick={() => navigate(`${photos.myValue}/${photos.title}`)}>{photos.title}</button>
          })}
        </div>
        <hr className='m-2 bg-black w-64' />
        <button className='text-l' onClick={() => navigate('/contacts')}>Contatti</button>
        <hr className='m-2 bg-black w-64' />
        <div className='flex flex-row space-x-6 justify-start grow pt-2'>
          <a href="https://www.facebook.com/beatricesalvagniphoto">
            <img alt="fb" src={fb} className="w-6 h-6" onClick={() => navigate('/')} />
          </a>
          <a href="https://www.instagram.com/beatricesalvagniph/">
            <img alt="ig" src={ig} className="w-6 h-6" onClick={() => navigate('/')} />
          </a>
          <a href="https://www.linkedin.com/in/beatrice-salvagni-95003018b/">
            <img alt="in" src={linkedin} className="w-6 h-6" onClick={() => navigate('/')} />
          </a>
        </div>
        <p className='text-xs py-2'>@ Beatrice Salvagni</p>
      </>
      :
      <div className='px-6 w-full pt-6 flex justify-center flex-col'>
        <div className='flex w-full justify-between'>
          <img alt="logo" src={logo} className="w-20 cursor-pointer" onClick={() => navigate('/')} />
          <button onClick={() => setOpen(!open)}>Menu</button>
        </div>
        {open && <>
          <button className='text-l pt-4' onClick={() => {
            navigate('/bio')
            setOpen(false)
          }}>Biografia</button>
          <hr className='my-2 bg-black w-full' />
          <button className={openPortfolio ? 'font-bold mb-2 shadow-lg w-full' : ''} onClick={() => {
            setOpenPortfolio(!openPortfolio)
            setOpenPortraits(false)
          }
          }>Portfolio</button>
          <div className=' overflow-y-auto max-h-60 w-full flex flex-col'>
            {openPortfolio && slides.filter(p => p.myValue === 'Portfolio').map(photos => {
              return <button className='pb-2' key={photos.title} onClick={() => {
                navigate(`${photos.myValue}/${photos.title}`)
                setOpen(false)
              }}>{photos.title}</button>
            })}
          </div>
          <hr className='my-2 bg-black w-full' />
          <button className={openPortraits ? 'font-bold mb-2 shadow-lg w-full' : ''} onClick={() => {
            setOpenPortraits(!openPortraits)
            setOpenPortfolio(false)
          }
          }>Ritratti</button>
          <div className=' overflow-y-auto max-h-60 w-full flex flex-col'>
            {openPortraits && slides.filter(p => p.myValue === 'Ritratti').map(photos => {
              return <button className='pb-2' key={photos.title} onClick={() => {
                navigate(`${photos.myValue}/${photos.title}`)
                setOpen(false)
              }}>{photos.title}</button>
            })}
          </div>
          <hr className='my-2 bg-black w-full' />
          <button onClick={() => {
            navigate('/contacts')
            setOpen(false)
          }}>Contatti</button>
        </>
        }
      </div>
    }
  </div>
}

export default Header