const Contacts = () => {
  return <div className="px-6 h-full">
    <h1 className="text-2xl pt-10 pb-4">Contact</h1>
    <div>
      <div>Email:</div>
      <div>
        <a href="beatricesalvagniart@gmail.com">
          beatricesalvagniart@gmail.com
        </a>
      </div>
    </div>
    <br />
    <div>
      <div>Telefono:</div>
      <div>
        <a href="tel:+393341369600">+39 334 13 69 600</a>
      </div>
    </div>
    <br />
    <div>
      <div>Instagram:</div>
      <div>
        <a href="https://www.instagram.com/beatricesalvagniph/">beatricesalvagniph</a>
      </div>
    </div>
    <br />
    <div>
      <div>Facebook:</div>
      <div>
        <a href="https://www.facebook.com/beatricesalvagniphoto">fb.com/beatricesalvagniphoto</a>
      </div>
    </div>
    <br />
    <div>
      <div>Linkedin:</div>
      <div>
        <a href="https://www.linkedin.com/in/beatrice-salvagni-95003018b">linkedin.com/in/beatrice-salvagni-95003018b</a>
      </div>
    </div>
  </div>
}

export default Contacts