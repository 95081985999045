import { useEffect, useState } from "react"
import { db, storage } from "../Firebase"
import { getDownloadURL, ref } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"

const Images = ({ isHome }) => {
  const [slides, setSlides] = useState([])
  const [imageUrls, setImageUrls] = useState([])
  const { category, photoid } = useParams()
  const [currentPhoto, setCurrentPhoto] = useState();
  const [fullScreen, setFullScreen] = useState(false);
  const navigate = useNavigate()

  const handlePrevious = () => {
    setCurrentPhoto((currentPhoto + imageUrls.length - 1) % imageUrls.length);
  };

  const handleNext = () => {
    setCurrentPhoto((currentPhoto + 1) % imageUrls.length);
  };

  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  useEffect(() => {
    db.collection("photos")
      .orderBy("order", "asc")
      .get()
      .then(snapshot => {
        const dbSlides = []
        snapshot.forEach(c => dbSlides.push(c.data()))
        setSlides(dbSlides)
      })
  }, [])

  useEffect(() => {
    if (!isHome) {
      const urls = []
      const filterShooting = slides?.filter(slide => slide.title === photoid)[0]
      filterShooting?.photos.forEach(slide => {
        const photoRef = ref(storage, `${category}/${photoid}/${slide}`);
        getDownloadURL(photoRef)
          .then((url) => {
            urls.push(url)
            setImageUrls([...urls])
          })
      })
    } else return
  }, [category, isHome, photoid, slides])

  useEffect(() => {
    if (isHome) {
      const urls = [];
      slides?.forEach(shoot => {
        const photoRef = ref(storage, `${shoot.myValue}/${shoot.title}/${shoot.photos[0]}`);
        getDownloadURL(photoRef)
          .then((url) => {
            urls.push({ url: url, title: shoot.title, category: shoot.myValue });
            setImageUrls([...urls]);
          });
      });
    } else return;
  }, [category, isHome, photoid, slides])

  return <div className="w-full pt-10 mb-2 h-full text-center text-2x">
    <h1>{photoid}</h1>
    <div className="overflow-y-auto h-[calc(100vh-6rem)]">
      <div className="gap-6 columns-1 md:columns-3 lg:columns-4 w-full px-6">
        {isHome ?
          imageUrls.map((url, index) => (
            <div className="flex flex-col mb-6">
              {/* <p className="">{url.title}</p> */}
              <div className="shadow-xl hover:shadow-none cursor-pointer" key={index}>
                <img alt={index} onClick={() => navigate(`${url.category}/${url.title}`)}
                  className="bg-contain bg-center" src={url.url} />
              </div>
            </div>
          ))
          :
          imageUrls.map((url, index) => (
            <div className="pt-6 shadow-xl hover:shadow-none cursor-pointer" key={index}>
              <img alt={index} onClick={() => {
                setCurrentPhoto(index)
                setFullScreen(true)
              }
              } className="bg-contain bg-center" src={url} />
            </div>
          ))
        }
      </div>
    </div>
    {(!!currentPhoto || currentPhoto === 0) && fullScreen && <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-black ${fullScreen ? "flex items-center justify-center" : ""
        }`}
    >
      {fullScreen && (
        <button
          className="absolute top-1 right-1 text-white text-4xl font-bold"
          onClick={handleExitFullScreen}
        >
          X
        </button>
      )}
      <button
        className={`absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-6xl font-bold ${!fullScreen ? "hidden" : ""
          }`}
        onClick={handlePrevious}
      >
        &lt;
      </button>
      <img
        className="max-w-full max-h-full object-contain cursor-pointer"
        src={imageUrls[currentPhoto]}
        alt={imageUrls[currentPhoto]}
      />
      <button
        className={`absolute top-1/2 right-0 transform -translate-y-1/2 text-white text-6xl font-bold ${!fullScreen ? "hidden" : ""
          }`}
        onClick={handleNext}
      >
        &gt;
      </button>
    </div>}
  </div>
}

export default Images
