import { Route, Routes, useNavigate } from 'react-router-dom'
import Header from './UI/Header'
import Bio from './View/Bio'
import Manager from './View/Manager'
import Images from './View/Images'
import Contacts from './View/Contacts'
import fb from './icon/fb.png'
import ig from './icon/ig.png'
import linkedin from './icon/in.png'
import { useEffect, useState } from 'react'

function App() {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
  const navigate = useNavigate()

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <div className="flex md:flex-row flex-col h-screen px-0 xl:px-60 font-cormorant">
      <div className="basis-1/6">
        <Header />
      </div>
      <div className="basis-5/6">
        <Routes>
          <Route path="/" element={<Images isHome />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/manager" element={<Manager />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/:category/:photoid" element={<Images isHome={false} />} />
        </Routes>
      </div>
      {windowSize[0] > 767 ? null : (
        <div className="w-full grow justify-center">
          <div className="flex flex-row w-full space-x-6 justify-center grow pt-2">
            <a href="https://www.facebook.com/beatricesalvagniphoto">
              <img alt="fb" src={fb} className="w-6 h-6" onClick={() => navigate('/')} />
            </a>
            <a href="https://www.instagram.com/beatricesalvagniph/">
              <img alt="ig" src={ig} className="w-6 h-6" onClick={() => navigate('/')} />
            </a>
            <a href="https://www.linkedin.com/in/beatrice-salvagni-95003018b/">
              <img alt="in" src={linkedin} className="w-6 h-6" onClick={() => navigate('/')} />
            </a>
          </div>
          <p className="text-xs pb-2 pt-4 text-center">@ Beatrice Salvagni</p>
        </div>
      )}
    </div>
  )
}

export default App
