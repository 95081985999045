import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getStorage } from 'firebase/storage'
import { GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAxS_-mqv0EDB3ELo_oLdX_sCkmX6Ne-kk',
  authDomain: 'beatrice-bd2b2.firebaseapp.com',
  projectId: 'beatrice-bd2b2',
  storageBucket: 'beatrice-bd2b2.appspot.com',
  messagingSenderId: '1041198395891',
  appId: '1:1041198395891:web:e45b814b5788edb215a286'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebaseApp.firestore()
const auth = firebase.auth()
const provider = new GoogleAuthProvider()
const storage = getStorage()

const getStorageUrl = fileID =>
  `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${fileID.replace(
    /\//g,
    '%2F'
  )}?alt=media`

export { db, auth, provider, storage, getStorageUrl }
