import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, db, storage } from "../Firebase";
import { selectUserName, setSignOutState, setUserLoginDetails } from "../reducer/user";
import { v4 as uuid } from 'uuid'
import React from 'react';
import firebase from 'firebase/compat/app'
import { ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from 'firebase/firestore';

const Manager = () => {
  const userName = useSelector(selectUserName);
  const options = ["Ritratti", "Portfolio"];
  const [myValue, setMyValue] = useState(options[0]);
  const [title, setTitle] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [photos, setPhotos] = useState([])
  const u = useSelector(state => state.user)

  const isAdmin = [
    'BjPAqqyIA3TOO0m6pWkwhzYZTYS2', // andrea
    'NaCtUgkIpfMrskIHwEKyubmdRAb2'  //beatrice
  ].includes(u.id)

  const dispatch = useDispatch()

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) setUser(user)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [u])

  const setUser = user => {
    dispatch(
      setUserLoginDetails({
        id: user.uid,
        name: user.displayName,
        email: user.email,
        photo: user.photoURL
      })
    )
  }

  const handleGoogleAuthClick = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const user = await firebase.auth().signInWithPopup(provider);
      setUser(user.user)
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignOutClick = async () => {
    try {
      await firebase.auth().signOut();
      dispatch(setSignOutState())
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = async () => {
    if (
      title === '' ||
      myValue === 'none' ||
      photos.length === 0
    ) {
      alert('Compila tutti i campi prima di procedere')
      return
    }

    setEnabled(false)
    const photoPaths = [];
    for (const file of photos) {
      const fileName = `${uuid()}.jpg`;
      const photoRef = ref(storage, `${myValue}/${title}/${fileName}`);
      const snapshot = await uploadBytes(photoRef, file);
      console.log(snapshot.state);
      photoPaths.push(fileName);
    }
    try {
      const newDoc = await addDoc(collection(db, "photos"), {
        myValue,
        title,
        photos: photoPaths,
        order: 999
      });
      console.log("Documento creato con ID: ", newDoc.id);
    } catch (err) {
      console.error(err);
      alert('Caricamento fallito: ' + err);
      return;
    }

    alert('Caricamento effettuato!');
    setEnabled(true);
  };

  return !isAdmin ?
    <div className="text-center flex flex-col h-full w-full space-y-4 pt-20 items-center bg-slate-400">
      <button onClick={handleGoogleAuthClick}>Sign in with Google</button>
    </div>
    :
    <>
      <div className="text-center flex flex-col h-full w-full space-y-4 pt-20 items-center bg-slate-400">
        <label>Tipo</label>
        <select
          disabled={!enabled}
          onChange={(e) => setMyValue(e.target.value)}
          defaultValue={myValue}
        >
          {options.map((option, idx) => (
            <option key={idx}>{option}</option>
          ))}
        </select>
        <label>Titolo</label>
        <input type="text" disabled={!enabled} value={title} onChange={e => setTitle(e.target.value)} />
        <label>Scegli foto</label>
        <input type="file" disabled={!enabled} multiple={true} onChange={e => setPhotos(e.target.files)} />
        <input type="button" disabled={!enabled} onClick={handleUpload} value="upload" />
        <p>{userName}</p>
        <button onClick={handleSignOutClick}>SIGN OUT</button>
      </div>
    </>
}

export default Manager
